import React from "react"
import { graphql } from "gatsby"
import BlockContent from '@sanity/block-content-to-react'
import { connect as connectFela } from "react-fela"
import { getGatsbyImageData } from 'gatsby-source-sanity'
import Vimeo from '@u-wave/react-vimeo';

import BlockQuote from "components/BlockQuote"
import BlogMeta from "components/BlogMeta"
import Breadcrumb from 'components/Breadcrumb'
import Container from "components/container"
import H4 from "components/typography/H4"
import IntroBlock from 'components/IntroBlock'
import Layout from "components/layout"
import MoreIdeas from "components/MoreIdeas"
import P from "components/typography/P"
import Profile from "components/Profile"
import Share from "components/Share"
import Ul from "components/typography/UL"
import UnderlinedTitle from "components/UnderlinedTitle"
import Value from "components/Value"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import { serializers as linkSerializers } from "components/BaseLink.js"
import ContentImage from "components/ContentImage"

/* Serializers */
const serializers = ({ styles, rules, pageColor }) => {
  return {
    list: props => (
      <Ul extend={{styles: rules.list}}>{props.children}</Ul>
    ),
    listItem: props => (
      <P tag={'li'} version="list">{props.children}</P>
    ),
    types: {
      valueStyleBlock: (props) => {
        const odd = props.node.imageSide === 'Left' ? false : true
        const fluidProps = getGatsbyImageData(props.node.block.image.asset._id, {maxWidth: 324}, props.options)
        return <Value extend={{container: rules.values}} odd={odd} image={fluidProps} title={props.node.block.title} content={props.node.block.content} />
      },
      contentImage: (props) => <ContentImage {...props} />, //id
      block: (props) => {
        const style = props.node.style
        if (style === 'xlarge') {
          return <P version="xlarge">{props.children}</P>
        }

        if (style === 'indentedxLarge') {
          return <div className={styles.indentedxLarge}><P version="xlarge">{props.children}</P></div>
        }

        if (style === 'title') {
          return <H4 extend={{styles: rules.h4}}>{props.children}</H4>
        }

        if (style === 'large') {
          return <P version='large'>{props.children}</P>
        }

        if (style === 'indentedLarge') {
          return <div className={styles.indentedLarge}><P version='large'>{props.children}</P></div>
        }

        if (style === 'blockquote') {
          return <BlockQuote extend={{styles: rules.blockquote}}>{props.children}</BlockQuote>
        }

        if (style === 'normal') {
          return <P version='body' extend={{styles: rules.normal}}>{props.children}</P>
        }

        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      sup: props => (
        <sup>
          {props.children}
        </sup>
      ),
      highlight: props => (
        <span style={{ paddingLeft: '3px', paddingRight: '3px', backgroundColor: pageColor }}>
          {props.children}
        </span>
      ),
      colored: props => (
        <span className={styles.colored}>
          {props.children}
        </span>
      ),
    }
  }
}

const Article = ({ path, pageContext, data, rules, styles }) => {
  const {
    title,
    meta,
    category,
    articleType,
    extraAuthor,
    published,
    _rawIntro,
    slug,
    image,
    author,
    videoId,
    _rawContent,
    countrySet
  } = data.sanityArticle
  const { siteMetadata: {siteUrl} } = data.site

  const pageColorLevel = ((articleType) => {
    switch(articleType) {
      case 'Event': return '300'
      case 'Video': return '500'
      default: return'50'
  }})(articleType)

  const linkColorLevel = ((articleType) => {
    switch(articleType) {
      case 'Event': return '700'
      case 'Video': return '500'
      default: return '200'
  }})(articleType)

  const pageColor = colors[`color${category.color}${pageColorLevel}`]
  const linkColor = colors[`color${category.color}${linkColorLevel}`]

  return (
    <Layout
      pageColor={pageColor}
      linkColor={linkColor}
      title={title}
      slug={{ current: path }}
      headerImage={image}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      {image && <IntroBlock
                  header
                  extend={{styles: rules.header}}
                  image={image} />}

      <Container withContentArea6 tag={'section'} extend={{styles: rules.container}}>
        <div className={styles.circle} />
        <Breadcrumb leftPart={"OUR THOUGHTS"} rightPart={category.title} extend={{styles: rules.breadcrumbs}} />
        <Share
          extend={{styles: rules.shareTop}}
          bgColor={colors[`color${category.color}50`]}
          url={siteUrl + '/our-thoughts/' + slug.current}
          country={countrySet}
        />
        <UnderlinedTitle style='hero' extend={{styles: rules.title}}>{title}</UnderlinedTitle>
        <BlogMeta author={author} extraAuthor={extraAuthor} published={published} extend={{styles: rules.meta}} />

        {videoId && <div className={styles.contentVideoContainer}>
          <Vimeo responsive color={colors.colorRoot600.replace(/^#/, '')} className={styles.contentVideo} video={videoId} />
        </div>}

        {!videoId && _rawIntro && (typeof _rawIntro === 'string') && <P version='large' extend={{styles: rules.intro}}>{_rawIntro}</P>}
        {!videoId && _rawIntro && (typeof _rawIntro !== 'string') && <BlockContent blocks={_rawIntro} serializers={serializers({rules, styles, pageColor: linkColor})} />}

        {_rawContent && <BlockContent blocks={_rawContent} serializers={serializers({rules, styles, pageColor: linkColor})}
                                      imageOptions={{w: 1263, h: 1016, fit: 'max'}}
                                      projectId={process.env.SANITY_PROJECT_ID || process.env.GATSBY_SANITY_PROJECT_ID}
                                      dataset={process.env.SANITY_DATASET || process.env.GATSBY_SANITY_DATASET} />}
      </Container>

      {author && <Container tag={'section'} withContentArea5 extend={{styles: rules.profile}}>
        <Profile profile={author} bgColor={colors[`color${category.color}50`]} />
      </Container>}

      <Container tag={'section'}>
        <Container withContentArea1 noPadding extend={{styles: rules.articleFooter}}>
          <Share
            extend={{styles: rules.shareBottom}}
            bgColor={colors[`color${category.color}50`]}
            url={siteUrl + '/our-thoughts/' + slug.current}
            country={countrySet}
          />

        </Container>
      </Container>

      <Container tag={'section'} onlyLeft extraLeft withContentArea5 extend={{styles: rules.moreIdeas}}>
        <MoreIdeas currentArticleSlug={slug} country={countrySet} />
      </Container>
    </Layout>
  )
}

const styles = () => ({
  header: {
    zIndex: 11,
  },
  container: {
    zIndex: 1,
    ...fluidValues({paddingTop: [60, 120]}),
  },
  // serializers
  colored: {
    color: colors.colorCanvas200,
  },
  large: {
    ':first-of-type': {
      ...fluidValues({marginBottom: [48, 90]})
    }
  },
  links: {
    textDecoration: 'underline',
  },
  indentedxLarge: {
    marginBottom: [48, 120],
    ...fluidValues({
      paddingLeft: [21, 110],
    }),
    desktopAndUp: {
      marginLeft: 110,
    }
  },
  indentedLarge: {
    marginBottom: [60, 120],
    ...fluidValues({
      paddingLeft: [21, 110],
    }),
    desktopAndUp: {
      marginLeft: 110,
    }
  },
  highlight: {
    display: 'inline-block',
    backgroundColor: colors.colorCanvas200,
    paddingLeft: 3,
    paddingRight: 3,
  },
  circle: {
    backgroundColor: colors.colorCanvas200,
    borderRadius: '50%',
    zIndex: -1,
    position: 'absolute',
    width: '60vw',
    height: '60vw',
    top: '26vw',
    left: '30px',
    maxWidth: 1200,
    maxHeight: 1200
  },
  normal: {
  },
  h4: {
    ...fluidValues({marginTop: [48, 90]})
  },
  list: {
    ...fluidValues({marginTop: [24, 32], marginBottom: [24, 32]}),
    width: 618,
    marginLeft: 'auto',
    marginRight: 'auto',

    phoneOnly: {
      width: 'auto',
    }
  },
  // --------
  shareTop: {
    position: 'absolute',
    ...fluidValues({top: [60, 120], right: [20, 80]}),
    phoneOnly: {
      display: 'none',
    }
  },
  profile: {
    desktopAndUp: {
      paddingLeft: 154,
    },

    ...fluidValues({
      paddingBottom: [60, 90],
    }),
  },
  articleFooter: {
    ...fluidValues({marginBottom: [40, 120], paddingBottom: [12, 32]}),
    borderBottom: '1px solid #000000',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  shareBottom: {
    marginLeft: 'auto',
  },
  breadcrumbs: {
    marginBottom: '34px',
  },
  title: {
    maxWidth: 624,
  },
  blockquote: {
    ...fluidValues({marginTop: [60, 90], marginBottom: [60, 90]}),
  },
  points: {
    ...fluidValues({marginTop: [40, 120]}),
  },
  description: {
    position: 'relative',

    onBefore: {
      ...fluidValues({marginLeft: [-24, -110], marginRight: [24, 110]}),
      backgroundColor: colors.colorConsole500,
      bottom: 0,
      content: '""',
      display: 'inline-block',
      height: '150%',
      maxHeight: '205px',
      position: 'absolute',
      width: 1,
    }
  },
  moreIdeas: {
    ...fluidValues({marginBottom: [40, 120]}),
    paddingTop: 1,
  },
  values: {
    ...fluidValues({
      paddingLeft: [21, 110],
    }),
  },
  contentVideoContainer: {
    ...fluidValues({marginTop: [40, 60]}),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentVideo: {
    border: `1px solid ${colors.colorRoot600}`,
    maxWidth: 1260,
    width: '87vw',
    flex: '1 0 auto',

    phoneOnly: {
      width: '100vw',
    }
  },
})

export default connectFela(styles)(Article)

export const query = graphql`
  query($slug: String!, $country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    sanityArticle(countrySet: { eq: $country }, slug: { current: { eq: $slug }}) {
      title
      countrySet
      meta {
        title
        description
        other {
          property
          content
        }
      }
      category {
        title
        color
      }
      articleType
      extraAuthor
      published
      _rawIntro(resolveReferences: {maxDepth: 10})
      videoId

      _rawContent(resolveReferences: {maxDepth: 10})

      slug {
        current
      }

      image {
        allowMobileFullScreen
        fitScreenWidth
        asset {
          gatsbyImageData(layout: CONSTRAINED, width:  1440)
        }
      }

      author {
        name
        photo {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  108)
          }
        }
        bio
        socials {
          name
          link
        }
      }
    }
  }
`
