/**
 * @fileOverview Profile component
 * @name Profile.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from "gatsby-plugin-image"

import P from "components/typography/P"
import Social from "components/Social"
import fluidDimensions from "utilities/fluidDimensions"
import fluidValues from "utilities/fluidValues"

/**
 * Profile component, shows a persons details and socials
 *
 * @param {object} styles Fela styles
 * @param {object} profile Profile object for a person
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled
 */
const Profile = ({styles, rules, profile, bgColor}) => (
  <div className={styles.styles}>
    {profile.photo && profile.photo.asset && <Img className={styles.img} image={profile.photo.asset.gatsbyImageData} alt={profile.name} />}
    <div className={styles.details}>
      <P version={'info'}>{profile.name}</P>
      <P version={'small'}>{profile.bio}</P>
    </div>
    <div className={styles.socials}>
      {profile.socials && profile.socials.map((social, index) => {
        return <Social extend={{styles: rules.social}} bgColor={bgColor} key={`profile-social-${index}`} network={social.name} link={social.link} />
      })}
    </div>
  </div>
)

/* Styles */
const styles = (props) => ({
  styles: {
    maxWidth: 434,
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    zIndex: 1
  },
  img: {
    ...fluidDimensions({width: [58, 108], height: [58, 108]}),
  },
  details: {
    flex: '1 1 0',
    flexBasis: '0',
    ...fluidValues({marginLeft: [12, 32]}),
  },
  socials: {
    flex: '0 0 100%',
    alignItems: 'center',
    display: 'flex',
  },
  social: {
    ...fluidValues({marginLeft: [6, 12]}),
  },
  followButton: {
    ...fluidValues({marginLeft: [12, 32]}),
  }
})

/* Component */
export default connectFela(styles)(Profile)
